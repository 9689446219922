.ahoy-badge {
  font-family: $ahoy-font-family-inter;
  font-size: inherit;
  font-weight: 500;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  align-items: center;
  outline: none;
  position: relative;

  transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &-neutral {
    background-color: adjust-color($ahoy-color-neutral-darkest, $alpha: -0.88);
    border-color: adjust-color($ahoy-color-neutral-darkest, $alpha: -0.88);
    color: $ahoy-color-teal-darkest;
  }

  &-aqua {
    background-color: adjust-color($ahoy-color-aqua-light, $alpha: -0.52) !important;
    border-color: adjust-color($ahoy-color-aqua-light, $alpha: -0.52) !important;
    color: $ahoy-color-aqua-darkest !important;

    &:hover {
      border-color: $ahoy-color-aqua-light !important;
    }
  }
}

.ahoy-badge-small {
  font-size: 12px;
  line-height: 18px;
  padding: 0 6px;
}
