.ahoy-button-secondary {
  background-color: #f7f7fa;
  border: 1px solid #c0c0c4;
  color: #1a1c20 !important;

  &:focus-visible {
    background-color: #f7f7fa;
    border-color: #82828c;
    box-shadow: 0 0 0 1px #82828c;
  }

  &:hover,
  &:active {
    background-color: #e4e4e6;
    border-color: #82828c;
    box-shadow: none;
  }

  &:disabled {
    background-color: #e4e4e6;
    border-color: #c0c0c4;
    color: #c0c0c4 !important;
  }

  &.ahoy-button-active {
    background-color: #64788f;
    border-color: #2a3b4d;
    color: #fff !important;
  }
}
