.ahoy-label-value-pair {
  display: flex;
  margin-bottom: 3px;

  .ahoy-label {
    font-family: $ahoy-font-family-inter;
    font-weight: 600;
    flex: 0 0 40%;
  }

  .ahoy-value {
    flex: 1 1 0%;
    overflow: hidden;
    word-break: break-word;
  }
}

.ahoy-label-value-pair-group {
  .ahoy-heading4 {
    color: $ahoy-color-neutral-darkest;
    margin-bottom: 6px;
  }
}

.ahoy-label-value-pair,
.ahoy-label-value-pair-group {
  & + .ahoy-label-value-pair-group {
    margin-top: 18px;
  }
}
