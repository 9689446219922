.ahoy-userinfo {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-gap: 18px;

  p {
    margin-bottom: 0; /* This can be removed once the global margin-bottom of 10px has been removed on p tags */
  }
}

.ahoy-userinfo-displayname {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
}
