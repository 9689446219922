div.ahoy-checkbox {
  user-select: none;
  box-sizing: border-box;
  display: inline-flex;
  width: 18px !important;
  height: 18px !important;
  background-color: #fff;
  border: 1px solid #c0c0c4;
  border-bottom: 1px solid #c0c0c4 !important; // otherwise the border-bottom on the last-child gets hidden by core
  border-radius: 4px;
  padding: 0;

  &:hover {
    background: #e4e4e6;
    border-color: #82828c;
    border-bottom-color: #82828c !important;
  }

  &.checked {
    background-color: #00b2b2;
    border-color: #008a8c;
    border-bottom-color: #008a8c !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICA8cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTUuODU3MjUsMTAuNTU2NSBMMi4yOTMyNSw2Ljk5MjUgQzEuOTAyMjUsNi42MDE1IDEuOTAyMjUsNS45Njk1IDIuMjkzMjUsNS41Nzg1IEMyLjY4NDI1LDUuMTg3NSAzLjMxNjI1LDUuMTg3NSAzLjcwNzI1LDUuNTc4NSBMNS44NTcyNSw3LjcyODUgTDEwLjI5MzI1LDMuMjkyNSBDMTAuNjg0MjUsMi45MDI1IDExLjMxNjI1LDIuOTAyNSAxMS43MDcyNSwzLjI5MjUgQzEyLjA5ODI1LDMuNjgzNSAxMi4wOTgyNSw0LjMxNjUgMTEuNzA3MjUsNC43MDY1IEw1Ljg1NzI1LDEwLjU1NjUgWiIvPgo8L3N2Zz4=);

    &:hover {
      background-color: #008a8c;
      border-color: #004b4d;
    }
  }
}
