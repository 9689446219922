.ahoy-input,
.ahoy-textarea,
.select-wrapper,
.select-wrapper select#id_select_segment {
  box-sizing: border-box;
  color: $color-primary-2;

  width: 100%;

  border-radius: 4px;

  font-family: $ahoy-font-family-inter;
  font-size: 14px;
  font-weight: 400;

  border: 1px solid $color-grey-5;
  background-color: $color-white;

  transition: 0.3s ease-in-out border, 0.3s ease-in-out box-shadow;

  &:hover,
  &:focus,
  &:active {
    &:not([disabled]) {
      border-color: $color-grey-6;
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-grey-6;
  }

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(130, 130, 140, 0.48);
  }

  &::-ms-clear {
    display: none;
  }

  &[disabled] {
    background: $ahoy-color-neutral;
    border-color: $ahoy-color-neutral;
  }
}

.ahoy-input,
.select-wrapper,
.select-wrapper select#id_select_segment {
  height: 36px !important;
}

.ahoy-input-large {
  height: 48px !important;
  padding: 12px;
}

.ahoy-input-small {
  height: 30px !important;
  padding: 0 5px;
  font-size: 14px;
  color: $ahoy-color-teal-darkest;
}

.ahoy-input-tiny {
  height: 24px !important;
  padding: 0 5px;
  font-size: 12px;
  color: $ahoy-color-teal-darkest;
}

.ahoy-select-wrapper {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  width: 100%;

  & select {
    padding-right: 30px;
    -moz-padding-start: 1px;
  }

  & select,
  &::after {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    grid-area: select;
  }

  &::after {
    content: "";
    justify-self: end;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4gIDxwYXRoIGZpbGw9IiM4MjgyOGMiIGZpbGwtb3BhY2l0eT0iMC44NCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMy40NjQ0NjYwOSw2LjA4NzEwNjkgTDcsOS42MjI2NDA4IEwxMC41MzU1MzM5LDYuMDg3MTA2OSBDMTAuOTI2MDU4Miw1LjY5NjU4MjYgMTAuOTI2MDU4Miw1LjA2MzQxNzYzIDEwLjUzNTUzMzksNC42NzI4OTMzMyBDMTAuMTQ1MDA5Niw0LjI4MjM2OTA0IDkuNTExODQ0NjQsNC4yODIzNjkwNCA5LjEyMTMyMDM0LDQuNjcyODkzMzMgTDcsNi43OTQyMTM2OCBMNC44Nzg2Nzk2Niw0LjY3Mjg5MzMzIEM0LjQ4ODE1NTM2LDQuMjgyMzY5MDQgMy44NTQ5OTAzOSw0LjI4MjM2OTA0IDMuNDY0NDY2MDksNC42NzI4OTMzMyBDMy4wNzM5NDE4LDUuMDYzNDE3NjMgMy4wNzM5NDE4LDUuNjk2NTgyNiAzLjQ2NDQ2NjA5LDYuMDg3MTA2OSBaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 14px;
    margin-right: 1px;
    pointer-events: none;
  }
}

.ahoy-input-error {
  border-color: $ahoy-color-ruby-dark;
  box-shadow: 0 0 0 1px $ahoy-color-ruby-dark;

  &:hover,
  &:focus,
  &:active {
    border-color: $ahoy-color-ruby-dark;
    box-shadow: 0 0 0 1px $ahoy-color-ruby-dark;
  }
}

.ahoy-textarea {
  &-small {
    min-height: 106px;
    padding: 2px 5px;
  }

  &-medium {
    min-height: 154px;
    padding: 5px 5px;
  }

  &-large {
    min-height: 202px;
    padding: 11px 5px;
  }
}
