.ahoy-section {
  border-bottom: 1px solid;
}

.ahoy-section-white {
  background-color: $ahoy-color-neutral-lightest;
  border-color: $ahoy-color-neutral;
  color: $ahoy-color-teal-darkest;
}

.ahoy-section-neutral {
  background-color: $ahoy-color-neutral-light;
  border-color: $ahoy-color-neutral;
  color: $ahoy-color-teal-darkest;
}

.ahoy-section-small {
  padding: 12px;
}

.ahoy-section-medium {
  padding: 18px;
}

.ahoy-section-large {
  padding: 24px;
}
