.ahoy-status-label {
  border-style: solid !important;
  border-width: 1px !important;
  display: inline-block;
  font-family: $ahoy-font-family-inter !important;
  font-weight: 500 !important;
  height: auto !important;
  text-transform: none;
  width: auto !important;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-aqua {
    background-color: $ahoy-color-aqua-lightest;
    border-color: $ahoy-color-aqua-light !important;
    color: $ahoy-color-aqua-darkest !important;
  }

  &-gold {
    background-color: $ahoy-color-gold-lightest;
    border-color: $ahoy-color-gold-light !important;
    color: $ahoy-color-gold-darkest !important;
  }

  &-mint {
    background-color: $ahoy-color-mint-lightest;
    border-color: $ahoy-color-mint-light !important;
    color: $ahoy-color-mint-darkest !important;
  }

  &-neutral {
    background-color: $ahoy-color-neutral-light;
    border-color: $ahoy-color-neutral-dark !important;
    color: $ahoy-color-neutral-darkest !important;
  }

  &-ruby {
    background-color: $ahoy-color-ruby-lightest;
    border-color: $ahoy-color-ruby-light !important;
    color: $ahoy-color-ruby-darkest !important;
  }

  &-violet {
    background-color: $ahoy-color-violet-lightest;
    border-color: $ahoy-color-violet-light !important;
    color: $ahoy-color-violet-darkest !important;
  }

  &-small {
    font-size: 12px !important;
    line-height: 18px !important;
    border-radius: 9px;
    padding: 0 6px !important;
  }

  &-medium {
    font-size: 14px !important;
    line-height: 24px !important;
    border-radius: 12px;
    padding: 0 6px !important;
  }
}
