.ahoy-button-group {
  display: flex;
  align-items: center;

  .ahoy-button-base {
    margin-left: 6px;
    margin-right: 6px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.ahoy-button-group-segmented {
  .ahoy-button-base {
    border-radius: 0;
    margin-left: -1px;
    margin-right: 0;

    &:first-child:not(:only-child) {
      border-radius: 4px 0 0 4px;
      margin-left: 0;
    }

    &:last-child:not(:only-child) {
      border-radius: 0 4px 4px 0;
    }

    &:only-child {
      border-radius: 4px;
    }

    &:focus,
    &:hover,
    &.ahoy-button-active {
      z-index: 1;
    }

    &.ahoy-button-active {
      pointer-events: none;
    }
  }
}
