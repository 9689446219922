.ahoy-wysiwyg-output {
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 18px;
  word-break: break-word;

  ol,
  p,
  ul {
    margin-bottom: 12px;
  }

  ol,
  ul {
    padding-left: 36px;
  }

  strong {
    font-family: $ahoy-font-family-inter;
    font-weight: 700;
  }
}
