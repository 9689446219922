.ahoy-pagination {
  align-items: center;
  cursor: default !important;
  display: flex !important;
  height: 30px !important;
  line-height: normal !important;
  overflow: visible !important;
  padding: 0 !important;
  width: auto !important;

  .ahoy-button-base {
    font-size: 10px;
    margin-right: 6px;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      i {
        transform: translate3d(-1px, 1px, 0);
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      i {
        transform: translate3d(1px, 1px, 0);
      }
    }

    &:nth-of-type(2),
    &:last-of-type {
      margin-right: 20px;
    }
  }

  .items-total,
  .page-status {
    cursor: default !important;
    display: inline-block;
    float: none;
    font-weight: 400;
    line-height: normal !important;
    overflow: visible;
    padding: 0;
    width: auto;
  }

  .items-total {
    color: #82828c;
    display: inline-flex;
  }

  .page-status {
    margin-right: 20px;
  }
}
