.ahoy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px !important;
  border: 1px solid rgb(192, 192, 196) !important;
  background-color: white;
  padding: 6px;
  min-width: 80px;
  background-position: center right;
  border-radius: 4px;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 15px) calc(0.7em + 2px),
    calc(100% - 10px) calc(0.7em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
