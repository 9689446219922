.ahoy-advanced-collapsible {
  & > .ahoy-link {
    display: flex;
    align-items: center;
    width: fit-content;

    .icon-14x14-chevron-down-outline-aqua,
    .icon-14x14-chevron-up-outline-aqua {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 6px;
    }
  }
}
