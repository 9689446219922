.ahoy-message {
  font-family: $ahoy-font-family-inter;
  color: $ahoy-color-teal-darkest;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  display: flex;
  padding: 24px;

  &-center {
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 18px;
      text-align: center;
    }
  }
}
