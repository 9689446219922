.ahoy-tag {
  font-family: $ahoy-font-family-inter;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  &-neutral {
    background-color: $ahoy-color-neutral;
    color: $ahoy-color-teal-darkest;
  }

  &-ruby {
    background-color: adjust_color($ahoy-color-ruby-light, $alpha: -0.52);
    border: 1px solid adjust_color($ahoy-color-ruby-light, $alpha: -0.52);
    color: $ahoy-color-ruby-darkest;
  }

  &-aqua {
    background-color: adjust_color($ahoy-color-aqua-light, $alpha: -0.52);
    border: 1px solid adjust_color($ahoy-color-aqua-light, $alpha: -0.52);
    color: $ahoy-color-aqua-darkest;
  }

  &-gold {
    background-color: adjust_color($ahoy-color-gold-light, $alpha: -0.52);
    border: 1px solid adjust_color($ahoy-color-gold-light, $alpha: -0.52);
    color: $ahoy-color-gold-darkest;
  }

  &-violet {
    background-color: adjust_color($ahoy-color-violet-light, $alpha: -0.52);
    border: 1px solid adjust_color($ahoy-color-violet-light, $alpha: -0.52);
    color: $ahoy-color-violet-darkest;
  }

  &-small {
    height: 24px;
    line-height: 24px;

    .ahoy-tag-label {
      font-size: 12px;
      line-height: 18px;
      padding: 0 6px;
    }
  }

  &-tiny {
    font-size: 12px;
    line-height: 18px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.ahoy-tag-group {
  .ahoy-tag {
    margin-bottom: 3px;
    margin-right: 6px;
  }
}

.ahoy-tag-group-overflow {
  display: none;
}

.ahoy-tag-group-show-all {
  border: 0;
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 0;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: $ahoy-color-neutral-dark;
  }
}
