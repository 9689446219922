.ahoy-button-icon {
  background-color: transparent;
  border: 0;
  color: $ahoy-color-neutral-darkest;
  padding: 0 !important;

  &:not(.ahoy-button-disabled) {
    &:focus-visible {
      box-shadow: 0 0 0 2px rgba(130, 130, 140, 0.24);
      outline: 0;
    }

    &:hover,
    &:active {
      background-color: rgba(130, 130, 140, 0.18);
    }
  }

  &.ahoy-button-small {
    height: 18px;
    min-width: 18px;
  }

  &.ahoy-button-medium {
    height: 30px;
    min-width: 30px;
  }
}
