@each $factor,
  $spacer in (0: 0, 1: 3, 2: 6, 3: 12, 4: 18, 5: 24, 6: 36, 7: 48, 8: 72)
{
  .ahoy-margin-#{$factor} {
    margin: #{$spacer}px !important;
  }

  .ahoy-margin-bottom-#{$factor} {
    margin-bottom: #{$spacer}px !important;
  }

  .ahoy-margin-left-#{$factor} {
    margin-left: #{$spacer}px !important;
  }

  .ahoy-margin-right-#{$factor} {
    margin-right: #{$spacer}px !important;
  }

  .ahoy-margin-top-#{$factor} {
    margin-top: #{$spacer}px !important;
  }

  .ahoy-margin-bottom-negative-#{$factor} {
    margin-bottom: #{$spacer * -1}px !important;
  }

  .ahoy-margin-left-negative-#{$factor} {
    margin-left: #{$spacer * -1}px !important;
  }

  .ahoy-margin-right-negative-#{$factor} {
    margin-right: #{$spacer * -1}px !important;
  }

  .ahoy-margin-top-negative-#{$factor} {
    margin-top: #{$spacer * -1}px !important;
  }

  .ahoy-padding-#{$factor} {
    padding: #{$spacer}px !important;
  }

  .ahoy-padding-bottom-#{$factor} {
    padding-bottom: #{$spacer}px !important;
  }

  .ahoy-padding-left-#{$factor} {
    padding-left: #{$spacer}px !important;
  }

  .ahoy-padding-right-#{$factor} {
    padding-right: #{$spacer}px !important;
  }

  .ahoy-padding-top-#{$factor} {
    padding-top: #{$spacer}px !important;
  }
}

@each $display in (inline, inline-block, block, flex, inline-flex) {
  .ahoy-display-#{$display} {
    display: $display;
  }
}

// fix for showing a display flex item via js (because it adds display: block inline)
.ahoy-display-flex[style*='display: block'] {
  display: flex !important;
}

@each $flex in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
  .ahoy-flex-#{$flex} {
    flex: $flex;
  }
}

@each $direction in (row, row-reverse, column, column-reverse) {
  .ahoy-flex-direction-#{$direction} {
    flex-direction: $direction;
  }
}

@each $wrap in (nowrap, wrap, wrap-reverse) {
  .ahoy-flex-wrap-#{$wrap} {
    flex-wrap: $wrap;
  }
}

@each $justify
  in (center, flex-start, flex-end, space-around, space-between, space-evenly)
{
  .ahoy-justify-content-#{$justify} {
    justify-content: $justify;
  }
}

@each $content-alignment
  in (center, flex-start, flex-end, space-around, space-between, space-evenly)
{
  .ahoy-align-content-#{$content-alignment} {
    align-content: $content-alignment;
  }
}

@each $item-alignment in (center, flex-start, flex-end, baseline, stretch) {
  .ahoy-align-items-#{$item-alignment} {
    align-items: $item-alignment;
  }
}

@each $self-alignment in (center, flex-start, flex-end, stretch) {
  .ahoy-align-self-#{$self-alignment} {
    align-self: $self-alignment;
  }
}

@each $text-alignment in (center, left, right) {
  .ahoy-text-align-#{$text-alignment} {
    text-align: $text-alignment;
  }
}

@each $overflow in (auto, hidden, scroll, visible) {
  .ahoy-overflow-#{$overflow} {
    overflow: $overflow;
  }

  .ahoy-overflow-x-#{$overflow} {
    overflow-x: $overflow;
  }

  .ahoy-overflow-y-#{$overflow} {
    overflow-y: $overflow;
  }
}

@each $position in (static, relative, absolute, fixed, sticky) {
  .ahoy-position-#{$position} {
    position: $position;
  }
}
