.ahoy-heading1 {
  font-family: $ahoy-font-family-inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.ahoy-heading2 {
  font-family: $ahoy-font-family-inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.ahoy-heading3 {
  font-family: $ahoy-font-family-inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.ahoy-heading4 {
  font-family: $ahoy-font-family-inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.ahoy-heading5 {
  font-family: $ahoy-font-family-inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
