.ahoy-text {
  color: $ahoy-color-teal-darkest;
  font-family: $ahoy-font-family-inter;
  font-weight: 400;

  &-display {
    font-size: 16px;
    line-height: 24px;
  }

  &-body {
    font-size: 14px;
    line-height: 21px;
  }

  &-small {
    font-size: 12px;
    line-height: 18px;
  }

  strong {
    font-family: $ahoy-font-family-inter;
    font-weight: 700;
  }
}

.ahoy-ui-text {
  font-family: $ahoy-font-family-inter;
  font-weight: 500;

  &-display {
    font-size: 16px;
    line-height: 24px;
  }

  &-body {
    font-size: 14px;
    line-height: 18px;
  }

  &-small {
    font-size: 12px;
    line-height: 18px;
  }

  strong {
    font-family: $ahoy-font-family-inter;
    font-weight: 700;
  }
}

.ahoy-monospaced {
  font-feature-settings: "tnum" on;
}

.ahoy-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
