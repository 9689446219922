.ahoy-link {
  color: $ahoy-color-aqua-dark !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    outline: 0;
  }
}

.ahoy-link-inherited {
  color: inherit !important;
}

.ahoy-link-inverted {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
