.ahoy-legacy-toggle,
.ahoy-legacy-toggle.compact {
  display: flex;

   > * {
    float: none;
  }

  .onoffholder {
    padding: 0;
    overflow: visible;

    label {
      display: flex;
      flex-shrink: 0;
      color: $ahoy-color-teal-darkest;
      font-family: $ahoy-font-family-inter;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      width: 120px !important;
      margin-right: 12px !important;
      margin-top: 3px;
      padding: 0;

      .ahoy-required-asterisk {
        color: $ahoy-color-ruby;
        font-family: $ahoy-font-family-inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .ahoy-tooltip {
        margin-left: auto;
        margin-top: 3px;
      }
    }
  }

  .onoff {
    width: 42px;
    height: 24px;
    box-sizing: border-box;
    background: $ahoy-color-neutral-light;
    border: 1px solid $ahoy-color-neutral-dark;
    border-radius: 24px;
    position: relative;
    display: inline-block;
    padding: 2px;
    cursor: pointer;
    transition: all 0.18s;

    b {
      display: none;
    }

    i {
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      margin-top: 2px;
      border-radius: 18px;
      background: $ahoy-color-neutral-lightest;
      border: 1px solid $ahoy-color-neutral-dark;
      transition: all 0.18s;
    }

    &.off {
      &:hover {
        background: $ahoy-color-neutral;
        border-color: $ahoy-color-neutral-darkest;
      }

      i {
        right: 20px;
      }
    }

    &.on {
      background: $ahoy-color-mint;
      border-color: $ahoy-color-mint-dark;

      &:hover {
        background: $ahoy-color-mint-dark;
        border-color: $ahoy-color-mint-darkest;
      }


      i {
        right: 2px;
        border-color: $ahoy-color-mint-dark;
      }
    }
  }

  &.ahoy-legacy-toggle-auto-width {
    .onoffholder label {
      width: auto !important;
    }
  }

  &.ahoy-legacy-toggle-full-width {
    .onoffholder label {
      width: 245px !important;
    }
  }

  &.ahoy-legacy-toggle-required {
    label:after {
      content: "\00a0 *";
      color: $ahoy-color-ruby;
    }
  }

  &.opa,
  &.disabled {
    pointer-events: none;

    .tooltip,
    .ahoy-tooltip {
      pointer-events: all;
    }

    .onoff {
      background: $ahoy-color-neutral;
      border-color: transparent;

      i {
        border-color: transparent;
      }

      &.on {
        background: $ahoy-color-mint-lightest;

        i {
          background: $ahoy-color-neutral-lightest;
        }
      }
    }
  }
}
