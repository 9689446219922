.ahoy-menu {
  background-color: $ahoy-color-neutral-lightest;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(42, 59, 77, 0.24);

  .ahoy-menu-inner {
    background-color: $ahoy-color-neutral-lightest;
    border-radius: 4px;
    padding: 6px 0;
  }

  &::before {
    border-radius: 4px;
    bottom: 2px;
    box-shadow: 0 4px 6px 0 rgba(42, 59, 77, 0.32);
    content: "";
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: -1;
  }
}

.ahoy-menu-dropdown {
  display: inline-block;
  position: relative;

  .ahoy-menu {
    margin-top: 3px;
    opacity: 0;
    position: absolute;
    transition-property: opacity, visibility;
    transition-duration: 0.1s;
    transition-delay: 0.1s;
    transition-timing-function: ease-in;
    visibility: hidden;
    z-index: 32;
  }

  &:hover {
    .ahoy-button-secondary {
      background-color: $ahoy-color-neutral;
      border-color: $ahoy-color-neutral-darkest;
    }

    .ahoy-button-icon {
      background-color: $ahoy-color-neutral;
    }

    .ahoy-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.ahoy-menu-dropdown-left .ahoy-menu {
  left: 0;
}

.ahoy-menu-dropdown-right .ahoy-menu {
  right: 0;
}

.ahoy-menu-item {
  align-items: center;
  background-color: $ahoy-color-neutral-lightest;
  border: 0;
  color: $ahoy-color-teal-darkest;
  display: flex;
  font-family: $ahoy-font-family-inter;
  font-weight: 400;
  height: 36px;
  font-size: 14px;
  line-height: 21px;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color 0.1s ease-in;
  user-select: none;
  width: 100%;
  white-space: nowrap;

  &:disabled {
    color: $ahoy-color-neutral-dark;

    i {
      opacity: 0.48;
    }
  }

  &:enabled:not(.is-selected) {
    cursor: pointer;

    &:hover {
      background-color: $ahoy-color-neutral-light;
    }
  }

  &.is-destructive {
    color: $ahoy-color-ruby-dark;
  }

  &.is-selected {
    background-color: $ahoy-color-aqua-lightest;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  i {
    background-image: url("#{$cdn-url}/images/actions.png?v=18");
    background-repeat: no-repeat;
    display: inline-block;
    height: 13px;
    margin-right: 12px;
    margin-bottom: 1px;
    opacity: 0.84;
    width: 13px;
  }

  &.articles i {
    background-position: 0px 0;
  }
  &.delete i {
    background-position: 0px -15px;
  }
  &.invoice i {
    background-position: 0px -29px;
  }
  &.refuse i {
    background-position: 0px -43px;
  }
  &.template i {
    background-position: 0px -58px;
  }
  &.confirm i {
    background-position: 0px -72px;
  }
  &.project i {
    background-position: 0px -87px;
  }
  &.accept i {
    background-position: 0px -100px;
  }
  &.send i {
    background-position: 0px -114px;
  }
  &.edit i {
    background-position: 0px -128px;
  }
  &.refresh i {
    background-position: 0px -141px;
  }
  &.todo i {
    background-position: 0px -155px;
  }
  &.callback i {
    background-position: 0px -169px;
  }
  &.meeting i {
    background-position: 0px -183px;
  }
  &.employee i {
    background-position: 0px -196px;
  }
  &.sale i {
    background-position: 0px -210px;
  }
  &.remove_tag i {
    background-position: 0px -223px;
  }
  &.add_tag i {
    background-position: 0px -236px;
  }
  &.merge i {
    background-position: 0px -250px;
  }
  &.priority i {
    background-position: 0px -263px;
  }
  &.deadline i {
    background-position: 0px -275px;
  }
  &.company i {
    background-position: 0px -288px;
  }
  &.dropbox i {
    background-position: 0px -305px;
  }
  &.google_drive i {
    background-position: 0px -321px;
  }
  &.label i {
    background-position: 0px -337px;
  }
  &.copy i {
    background-position: 0px -353px;
  }
}

.ahoy-menu-divider {
  background-color: $ahoy-color-neutral;
  border: 0;
  display: block;
  height: 1px;
  outline: 0;
  padding: 0;
  width: 100%;
}

.ahoy-menu-title {
  align-items: center;
  color: $ahoy-color-teal-darkest;
  display: flex;
  height: 36px;
  font-family: $ahoy-font-family-inter;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 18px;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
}
