.ahoy-counter {
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  display: inline-block;
  font-family: $ahoy-font-family-inter;
  font-feature-settings: "tnum" on;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  min-width: 18px;
  text-align: center;
}

.ahoy-counter-neutral {
  background-color: $ahoy-color-neutral-darkest;
}

.ahoy-counter-mint {
  background-color: $ahoy-color-mint;
}

.ahoy-counter-small {
  padding: 3px 6px;
}
.ahoy-counter-medium {
  padding: 6px;
}
