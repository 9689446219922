.ahoy-widget {
  background-color: $ahoy-color-neutral-lightest;
  border: 1px solid $ahoy-color-neutral;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;

  margin-bottom: 30px; /* It actually doesn't belong here since this is a layout responsibility */
}

.ahoy-widget-header {
  align-items: center;
  border-bottom: 1px solid $ahoy-color-neutral;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding-right: 18px;

  &.has-padding {
    padding: 6px 18px;
  }

  .ahoy-heading3 {
    color: $ahoy-color-teal-darkest;
    margin-bottom: 0; // Can be removed once the global h3 margin-bottom style has been removed.
  }

  .ahoy-tabs {
    margin-bottom: -1px;

    .ahoy-tab {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.ahoy-widget-actions {
  align-items: center;
  display: flex;

  > * {
    margin-left: 12px;
  }
}

.ahoy-widget-body {
  &.has-padding {
    padding: 18px;

    .listview {
      margin-left: -18px;
      margin-right: -18px;
      width: calc(100% + 36px);
    }

    .listview:not(.overview-page-listview) {
      margin-top: -18px;
    }
  }

  .undo-horizontal-widget-padding {
    margin-left: -18px !important;
    margin-right: -18px !important;
  }

  .ahoy-widget.no-footer & {
    padding-bottom: 0;
  }
}

.ahoy-tabbed-body {
  &.has-padding {
    padding-bottom: 0;
  }

  .ahoy-tab-content {
    display: none;
  }

  .ahoy-tab-content--active {
    display: block;
  }
}
