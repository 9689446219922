.ahoy-avatar {
  display: inline-block;
  flex-shrink: 0;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.ahoy-avatar-initials {
  align-items: center;
  color: $ahoy-color-neutral-lightest;
  display: flex;
  font-family: $ahoy-font-family-inter;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
}

.ahoy-avatar-tiny {
  font-size: 8px;
  height: 24px;
  width: 24px;
}

.ahoy-avatar-small {
  font-size: 12px;
  height: 36px;
  width: 36px;
}

.ahoy-avatar-normal {
  font-size: 12px;
  height: 48px;
  width: 48px;
}

.ahoy-avatar-circle {
  img,
  .ahoy-avatar-initials {
    border-radius: 50%;
  }
}

.ahoy-avatar-rounded {
  img,
  .ahoy-avatar-initials {
    border-radius: 4px;
  }
}

.ahoy-avatar-aqua {
  background-color: $ahoy-color-aqua;
}

.ahoy-avatar-gold {
  background-color: $ahoy-color-gold;
}

.ahoy-avatar-mint {
  background-color: $ahoy-color-mint;
}

.ahoy-avatar-neutral {
  background-color: $ahoy-color-neutral-darkest;
}

.ahoy-avatar-ruby {
  background-color: $ahoy-color-ruby;
}

.ahoy-avatar-teal {
  background-color: $ahoy-color-teal;
}

.ahoy-avatar-violet {
  background-color: $ahoy-color-violet;
}
