.ahoy-bullet {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.ahoy-bullet-small {
  height: 9px;
  width: 9px;
}
.ahoy-bullet-medium {
  height: 12px;
  width: 12px;
}

.ahoy-bullet-mint {
  background-color: $ahoy-color-mint-dark;
}

.ahoy-bullet-neutral {
  background-color: $ahoy-color-neutral-dark;
}

.ahoy-bullet-ruby {
  background-color: $ahoy-color-ruby-dark;
}
