.ahoy-tabs {
  padding-left: 6px;
  padding-right: 6px;

  .ahoy-tab {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-family: $ahoy-font-family-inter;
    font-weight: 500;
    height: 48px;
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 6px;
    padding-right: 6px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      box-shadow: inset 0px -2px 0px 0px $ahoy-color-mint;
      height: 3px;
      width: 100%;
      bottom: 0;
      left: 0;
      transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      transform: scale(0, 1);
    }

    &.selected::after {
      transform: scale(1, 1);
    }
  }
}

.ahoy-tab-actions {
  display: none !important;

  &.selected {
    display: flex !important;
  }
}
