.ahoy-timepicker-container {
  .timepicker {
    width: 168px;
  }

  .icon-timeduration,
  .icon-timetracker {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICA8cGF0aCBmaWxsPSIjODI4MjhjIiBmaWxsLW9wYWNpdHk9IjAuODQiIGQ9Ik03LDMgQzQuNzkxMzY5NSwzIDMsNC43OTEzNjk1IDMsNyBDMyw5LjIwOTM1NDIyIDQuNzkwODkzMTcsMTEgNywxMSBDOS4yMDk4MzA1LDExIDExLDkuMjA5ODMwNSAxMSw3IEMxMSw0Ljc5MDg5MzE3IDkuMjA5MzU0MjIsMyA3LDMgWiBNOSwxLjM0MTM2ODgzIEMxMS4zMzA2ODQyLDIuMTY1MDgwNDIgMTMsNC4zODc4NjEyOSAxMyw3IEMxMywxMC4zMTQ0IDEwLjMxNDQsMTMgNywxMyBDMy42ODY4LDEzIDEsMTAuMzE0NCAxLDcgQzEsNC4zODgwMzgyMSAyLjY2OTgzNTQ0LDIuMTY1MzgxNSA1LDEuMzQxNTM2MjIgTDUsMSBDNSwwLjQ0NzcxNTI1IDUuNDQ3NzE1MjUsMS4wMTQ1MzA2M2UtMTYgNiwwIEw4LDAgQzguNTUyMjg0NzUsLTEuMDE0NTMwNjNlLTE2IDksMC40NDc3MTUyNSA5LDEgTDksMS4zNDEzNjg4MyBaIE03LDQgQzcuNTUyMjg0NzUsNCA4LDQuNDQ3NzE1MjUgOCw1IEw4LDcgQzgsNy41NTIyODQ3NSA3LjU1MjI4NDc1LDggNyw4IEM2LjQ0NzcxNTI1LDggNiw3LjU1MjI4NDc1IDYsNyBMNiw1IEM2LDQuNDQ3NzE1MjUgNi40NDc3MTUyNSw0IDcsNCBaIi8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    top: 0;

    &::before {
      display: none;
    }
  }
}
